
import React from 'react'
import View from '../View'
import Button from '../Button'

export default ({a, ctx: {api}}) => (
  <View styles={styles.container}>
    Map
  </View>
)

const styles = {
}
